import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { FlashOffOutlined } from '@material-ui/icons';

const initialState = {
  isLoading: false,
  error: false,
  talentCard: null,
  activeStep: 3,
  //AK
  imgData: null,
  project: null,
  certificate: null,
  document: null,
  //multistepForm
  activeSteps: 0,
  file: null,
  contactNum: '',
  notification: true,
  currentCity: '',
  gender: '',
  groups: [],
  skills: [],
  success: false,
  jobStatus: '',
  jobRoles: '',
  subJobRoles: '',
  position: {
    fullTime: false,
    partTime: false,
    internship: false,
    freelance: false,
    fresher:false
  },
  termPeriod: '',
  experience:"",
  locations: [],
  workmode: {
    onSite: false,
    remote: false,
    hybrid: false
  },
  presentSalary: {
    presentCurrency: '' || "INR",
    presentValue: ''
  },
  expectedSalary: {
    expectedCurrency: '' || "INR",
    expectedValue: ''
  },
  hourlyRates: {
    hourlyRatesCurrency:'',
    hourlyRatesValue:''
  },
  availability: {
    availabilityNumber:'',
    availabilityType:''
  },
  companyType: [],
  companySize: [],
  chatsdatas: {},
  allchat: [],
  shortAssessment: {
    isLoading: false,
    data: null,
    error: null,
  }
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getTalentCardSuccess(state, action) {
      console.log('action: ' + action.payload);
      state.isLoading = false;
      state.talentCard = action.payload.talent;
    },
    TalentCardSuccess(state, action) {
      state.isLoading = false;
      state.talentCard = action.payload;
      state.activeStep += 1;
    },
    updateTalentCardSuccess(state, action) {
      state.isLoading = false;
      state.talentCard = action.payload;
    },
    createExperienceSuccess(state, action) {
      state.isLoading = false;
      state.talentCard = {
        ...state.talentCard,
        workExperience: action.payload
      };
    },
    createEducationSuccess(state, action) {
      state.isLoading = false;
      state.talentCard = {
        ...state.talentCard,
        education: action.payload
      };
    },
    onBackStep(state) {
      state.activeStep -= 1;
    },

    onNextStep(state) {
      state.activeStep += 1;
    },

    //AK
    getBoosterCardSuccess(state, action) {
      state.isLoading = false;
      state.booster = action.payload;
    },
    imageUploadSuccess(state, action) {
      state.isLoading = false;
      state.imgData = action.payload;
      state.talentCard = {
        ...state.talentCard,
        profilePictureURL: action.payload
      };
      // console.log(state.imgData);
    },
    projectUploadSuccess(state, action) {
      state.isLoading = false;
      state.talentCard.workSampleProject = action.payload;
    },
    certificateUploadSuccess(state, action) {
      state.isLoading = false;
      state.talentCard.certificates = action.payload;
    },
    documentUploadSuccess(state, action) {
      state.isLoading = false;
      state.talentCard.candidateDocuments = action.payload;
    },
    //multistepForm
    setNext: (state) => {
      state.activeSteps++;
    },
    setPrev: (state) => {
      state.activeSteps--;
    },
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setContactNum: (state, action) => {
      state.contactNum = action.payload;
    },
    setCurrentCity: (state, action) => {
      state.currentCity = action.payload;
    },
    getNotify: (state, action) => {
      state.notification = action.payload;
    },
    genderError: (state) => {
      state.error = true;
    },
    jobStatusError: (state) => {
      state.error = true;
    },
    jobRoleError: (state) => {
      state.error = true;
    },
    setGroups: (state, action) => {
      const group = action.payload;
      const isGroupChecked = state.groups.includes(group);

      if (isGroupChecked) {
        return {
          ...state,
          groups: state.groups.filter((item) => item !== group)
        };
      } else {
        return {
          ...state,
          groups: [...state.groups, group]
        };
      }
    },
    addTag: (state, action) => {
      state.skills.push(action.payload);
    },
    removeTag: (state, action) => {
      state.skills = state.skills.filter((tag) => tag !== action.payload);
    },
    setFile: (state, action) => {
      state.file = action.payload;
    },
    setError: (state) => {
      state.error = true;
      state.success = false;
    },
    setSuccess: (state) => {
      state.success = true;
      state.error = false;
    },
    setJobStatus: (state, action) => {
      state.jobStatus = action.payload;
    },
    setJobRoles: (state, action) => {
      state.jobRoles = action.payload;
    },
    setSubJobs: (state, action) => {
      state.subJobRoles = action.payload;
    },
    togglePosition: (state, action) => {
      const checkboxName = action.payload;
      return {
        ...state,
        position: {
          ...state.position,
          [checkboxName]: !state.position[checkboxName]
        }
      };
    },
    setWorkmode: (state, action) => {
      const mode = action.payload;
      const updatedWorkmode = {
        ...state.workmode,
        [mode]: !state.workmode[mode]
      };
      if (!updatedWorkmode.onSite && !updatedWorkmode.hybrid) {
        return {
          ...state,
          workmode: updatedWorkmode,
          locations: []
        };
      }
      return {
        ...state,
        workmode: updatedWorkmode
      };
    },
    setTermPeriod: (state, action) => {
      state.termPeriod = action.payload;
    },
    setLocation: (state, action) => {
      state.locations = action.payload;
    },
    addLocations: (state, action) => {
      state.locations.push(action.payload);
    },
    removeLocations: (state, action) => {
      state.locations = state.locations.filter(
        (location) => location !== action.payload
      );
    },
    setPresentSalary: (state, action) => {
      state.presentSalary = action.payload;
    },
    setExpectedSalary: (state, action) => {
      state.expectedSalary = action.payload;
    },
    setHourlyRates: (state, action) => {
      state.hourlyRates = action.payload;
    },
    setAvailability: (state, action) => {
      state.availability = action.payload;
    },
    setCompanyType: (state, action) => {
      const company = action.payload;
      const isCompanyChecked = state.companyType.includes(company);

      if (isCompanyChecked) {
        return {
          ...state,
          companyType: state.companyType.filter((item) => item !== company)
        };
      } else {
        return {
          ...state,
          companyType: [...state.companyType, company]
        };
      }
    },
    setCompanySize: (state, action) => {
      const company = action.payload;
      const companySizeChecked = state.companySize.includes(company);

      if (companySizeChecked) {
        return {
          ...state,
          companySize: state.companySize.filter((item) => item !== company)
        };
      } else {
        return {
          ...state,
          companySize: [...state.companySize, company]
        };
      }
    },
    resetFormData: (state) => {
      return {
        ...initialState,
        activeSteps: 0
      };
    },
    setChatData: (state, action) => {
      state.chatsdatas = action.payload;
    },
    getAllchatData: (state, action) => {
      state.allchat = action.payload;
    },
   

  }
});

//Reducer
export default slice.reducer;

//Action
export const {
  onNextStep,
  onBackStep,
  setNext,
  setPrev,
  activeSteps,
  setGender,
  genderError,
  jobStatusError,
  jobRoleError,
  setGroups,
  setFile,
  setError,
  setSuccess,
  addTag,
  removeTag,
  setJobStatus,
  setJobRoles,
  togglePosition,
  setTermPeriod,
  addLocations,
  removeLocations,
  setWorkmode,
  setPresentSalary,
  setExpectedSalary,
  setHourlyRates,
  setAvailability,
  setCompanyType,
  setCompanySize,
  setSubJobs,
  resetFormData,
  startLoading,
  setContactNum,
  getNotify,
  setCurrentCity,
  
} = slice.actions;

// ----------------------------------------------------------------------




export function getTalentCard(talent_id) {
  //console.log(talent_id,"I am authorised User");
  return async (dispatch) => {
    //console.log( 'talentCard Dataaaaaaaaaa');

    dispatch(slice.actions.startLoading());
    try {
      //console.log( 'talentCard Dataaaaaaaaaa2',talent_id);


      const response = await axios.get(`/api/talent/${talent_id}`);
      dispatch(slice.actions.getTalentCardSuccess(response.data));
      //console.log("talent CArd redux" , response.data.user);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getTalentCardById(talent_id) {
  console.log(talent_id,"i am not authorised User");
  return async (dispatch) => {
    console.log( 'talentCard Dataaaaaaaaaa');

    dispatch(slice.actions.startLoading());
    try {
      //console.log( 'talentCard Dataaaaaaaaaa2',talent_id);


      const response = await axios.get(`/api/auth/${talent_id}`);
      dispatch(slice.actions.getTalentCardSuccess(response.data));
      console.log(response.data , "api/auth")
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createTalentCard(talent_id, data, resume) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/api/talent/${talent_id}`, data).then((response) => {
        const headers = {
          Accept: 'multipart/form-data'
        };
        if (resume) {
          const fd = new FormData();
          fd.append('resume', resume);
          return axios
            .post(`/api/talent/${talent_id}/resume/upload`, fd, headers)
            .then((resp) =>
              dispatch(slice.actions.TalentCardSuccess(resp.data))
            );
        }
        dispatch(slice.actions.TalentCardSuccess(response.data));
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateTalent(talent_id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/talent/${talent_id}`, data);
      dispatch(slice.actions.updateTalentCardSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createExperience(talent_id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log("hhK")
      const response = await axios.post(
        `/api/talent/${talent_id}/workexperience`,
        data
      );
      dispatch(slice.actions.createExperienceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateExperience(talent_id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `/api/talent/${talent_id}/workexperience/${data._id}`,
        data
      );
      dispatch(slice.actions.createExperienceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteExperience(talent_id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `/api/talent/${talent_id}/workexperience/${data._id}`,
        data
      );
      dispatch(slice.actions.createExperienceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEducation(talent_id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/api/talent/${talent_id}/education`,
        data
      );
      dispatch(slice.actions.createEducationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEducation(talent_id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `/api/talent/${talent_id}/education/${data._id}`,
        data
      );
      dispatch(slice.actions.createEducationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEducation(talent_id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `/api/talent/${talent_id}/education/${data._id}`,
        data
      );
      dispatch(slice.actions.createEducationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
//AK
// export function getBooster(talent_id) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(
//         `https://dev-admin.highpo.co/api/talent/docs/docs/${talent_id}/documents/upload`
//       );
//       dispatch(slice.actions.getBoosterCardSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

export function imageUpload(image) {
  let headers = {
    // Accept: "multipart/form-data",
    'Content-Type': 'multipart/form-data'
  };
  const fd = new FormData();
  fd.append('profile', image);
  let token = '';
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/api/talent/profile-photo`, fd, headers).then((resp) => {
        dispatch(slice.actions.imageUploadSuccess(resp.data.profilePictureURL));
        // console.log(resp)
      });

      // dispatch(slice.actions.imageUploadSuccess(image));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function projectUpload(talent_id, projects) {
  let headers = {
    Accept: 'multipart/form-data'
  };

  const fd = new FormData();
  fd.append('file', projects.file);
  fd.append('title', projects.title);
  fd.append('link', projects.link);
  fd.append('description', projects.description);
  fd.append('boosterType', 'work-sample');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/api/talent/boosters`, fd, headers).then((resp) => {
        let len = resp.data.length;
        dispatch(
          slice.actions.projectUploadSuccess(resp.data.workSampleProject)
        );
        //console.log(resp);
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function projectDelete(boosterId, data) {
  let token = '';
  token = JSON.parse(window.localStorage.getItem('authUser'));
  const headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + token
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios
        .delete(
          `/api/talent/boosters/${boosterId}`,
          { data },
          { headers }
          // data
        )
        .then((resp) => {
          let len = resp.data.workSampleProject.length;
          dispatch(
            slice.actions.projectUploadSuccess(resp.data.workSampleProject)
          );
          //console.log(resp.data, 'reposonse delete');
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function certiUpload(talent_id, data) {
  let headers = {
    // Accept: "multipart/form-data",
    'Content-Type': 'multipart/form-data'
  };
  const fd = new FormData();
  // fd.append('file', data);

  // const fd = new FormData();
  fd.append('file', data.file);
  fd.append('title', data.title);
  fd.append('boosterType', 'certificate');
  // fd.append('link', data.link);
  // fd.append('description', data.description);
  let token = '';
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/api/talent/boosters`, fd, headers).then((resp) => {
        dispatch(slice.actions.certificateUploadSuccess(resp.data));
        console.log(resp);
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function certiDelete(boosterId, data) {
  let token = '';
  token = JSON.parse(window.localStorage.getItem('authUser'));
  const headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + token
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios
        .delete(
          // `/api/talent/boosters/${talent_id}/upload`,
          `/api/talent/boosters/${boosterId}`,
          { data },
          { headers }
        )
        .then((resp) => {
          dispatch(
            slice.actions.certificateUploadSuccess(resp.data.certificates)
          );
          console.log(resp);
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function docuUpload(talent_id, data) {
  let headers = {
    // Accept: "multipart/form-data",
    'Content-Type': 'multipart/form-data'
  };
  const fd = new FormData();
  // fd.append('file', data);

  // const fd = new FormData();
  fd.append('file', data.file);
  fd.append('title', data.title);
  fd.append('boosterType', 'document');
  // fd.append('description', data.description);
  let token = '';
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/api/talent/boosters`, fd, headers).then((resp) => {
        dispatch(slice.actions.documentUploadSuccess(resp.data));
        console.log(resp);
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function docuDelete(talent_id, data) {
  let token = '';
  token = JSON.parse(window.localStorage.getItem('authUser'));
  const headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + token
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios
        .delete(
          `api/talent/candidatedocs/${talent_id}/upload`,
          { data },
          { headers }
        )
        .then((resp) => {
          dispatch(
            slice.actions.documentUploadSuccess(resp.data.candidateDocuments)
          );
          console.log(resp);
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------------------
export function getChats(email) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `https://chatapi.qrata.ai/user?email=${email}`
      );
      dispatch(slice.actions.getAllchatData(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function chatData(senderEmail, receiverEmail) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('https://chatapi.qrata.ai/chats', {
        senderEmail,
        receiverEmail
      });
      dispatch(slice.actions.setChatData(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
